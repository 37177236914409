import { useContext } from 'react'
import { ModalContext } from "../providers/ModalProvider";

export const useModal = () => {
    const { isModalOpen, hideModal, showModal } = useContext(ModalContext)
    return {
        isModalOpen,
        showModal,
        hideModal,
    }
}
