import * as React from "react";

import styles from "./UserBio.module.css";

interface UserBioProps {
  avatarURL: string;
  name: string;
  surname: string;
  title: string;
  description: string;
}

export const UserBio = ({
  avatarURL,
  name,
  surname,
  description,
  title,
}: UserBioProps) => (
  <div className={styles.wrapper}>
    <div className={styles.avatar}>
      <img src={avatarURL} alt={name + " " + surname} />
    </div>
    <p className={styles.name}>
      {name} {surname}
    </p>
    <span className={styles.title}>{title}</span>
    <p className={styles.desc}>{description}</p>
  </div>
);
