import * as React from "react";

import { Modal, ModalVariant } from "./Modal/Modal";
import { ModalType } from "../../providers/ModalProvider";
import { RoleSkillList } from "../SectionRoles/SectionRoles";
import { useModal } from "../../hooks/useModal";

export const ModalQA = () => {
  const { hideModal } = useModal();

  return (
    <Modal onClose={() => hideModal(ModalType.qa)} variant={ModalVariant.large}>
      <RoleSkillList>
        <li>Czy jesteś skrupulatny/a?</li>
        <li>
          Czy jesteś w stanie zdefiniować, jaki cel ma testowanie
          oprogramowania?
        </li>
        <li>
          Czy testowałeś/aś napisany przez siebie kod manualnie lub z
          wykorzystaniem narzędzi programistycznych?
        </li>
        <li>
          Czy korzystałeś/aś kiedyś z jakiegokolwiek frameworka testowego (np.
          Junit, TestNG, Spock)?
        </li>
        <li>Czy wiesz, co to jest piramida testów?</li>
        <li>Czy wiesz, czym się różnią testy biało od czarnoskrzynkowych?</li>
        <li>Czy wiesz, jak wykorzystywać mocki/stuby?</li>
        <li>Czy potrafisz debugować aplikację?</li>
      </RoleSkillList>
    </Modal>
  );
};
