import * as React from "react";
import { PropsWithChildren } from "react";

import { Section } from "../Section/Section";
import { Container } from "../Layout/Layout";
import { ButtonBackground } from "../images/button";

import styles from "./SectionTimeline.module.css";

export const SectionTimeline = (props: PropsWithChildren<{}>) => (
  <Section id={"timeline"} backgroundColor={"#000045"}>
    <div className={styles.paddingTop}>
      <Container>
        <h2 style={{ color: "#fff", fontSize: "28px" }}>HARMONOGRAM</h2>
      </Container>
      <div className={styles.images}>{props.children}</div>
      <div className={styles.wrapper}>
        {/*<a*/}
        {/*  href="https://hargreaveslansdown.wd3.myworkdayjobs.com/en-US/HLTech/job/Warsaw/FinTech-Challenge_R283/apply?source=Landing_Page"*/}
        {/*  target={"_blank"}*/}
        {/*  rel={"norefferer"}*/}
        {/*  className={styles.graphicButton}*/}
        {/*>*/}
        {/*  <ButtonBackground />*/}
        {/*</a>*/}
      </div>
    </div>
    <div className={styles.triangle} />
  </Section>
);
