import * as React from "react";

import { Section } from "../Section/Section";
import { Container } from "../Layout/Layout";
import { Button } from "../Button/Button";
import { SectionHeader } from "../SectionHeader/SectionHeader";

import IconArrow from "../../images/icons/arrow.svg";
import Terms from "../../images/terms.pdf";
import styles from "./SectionRecruitment.module.css";

export const SectionRecruitment = () => (
  <Section id={"recruitment-process"} backgroundColor={"#000045"}>
    <div className={styles.background}>
      <Container>
        <SectionHeader header={"ETAPY"} highlighted={"REKRUTACJI"} />
        <div className={styles.grid}>
          <Phase
            number={1}
            header={"Zarejestruj się do 26.08.2020!"}
            description={
              <>
                <p>
                  Wyślij swoje CV, wskazując w formularzu aplikacyjnym, czy
                  aplikujesz jako członek zespołu, czy indywidualnie oraz
                  odpowiedz na pozostałe pytania zawarte w formularzu.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>Ważne!</span> Zespół musi
                  posiadać kompetencje we wszystkich czterech obszarach:
                  Backend, Frontend, QA, Analiza IT, przy czym dopuszczamy
                  sytuację, że jedna osoba odpowiada za dwa różne obszary oraz
                  dwie osoby odpowiadają za ten sam obszar. Zespół musi mieć
                  minimum 4, a maksymalnie 5 osób.
                </p>
                <p>
                  Jeśli aplikujesz indywidualnie, również możesz wybrać
                  maksymalnie 2 obszary.
                </p>
              </>
            }
          />
          <Phase
            header={
              <span>
                Rozmowa telefoniczna <br />
                <span style={{ fontWeight: 400 }}>
                  (z wybranymi zespołami/osobami)
                </span>
              </span>
            }
            number={2}
            description={
              <p>
                Zadamy kilka pytań i upewnimy się, że znacie{" "}
                <a style={{ color: "#00DEB2" }} href="#timeline">
                  harmonogram
                </a>{" "}
                i{" "}
                <a target={"_blank"} style={{ color: "#00DEB2" }} href={Terms}>
                  regulamin konkursu
                </a>
              </p>
            }
          />
          <Phase
            header={
              "Rozmowa techniczna i HR w formie online (pierwsze dwa tygodnie września)."
            }
            number={3}
            description={
              <p>
                Podczas rozmowy technicznej sprawdzana będzie{" "}
                <a style={{ color: "#00DEB2" }} href="#roles">
                  wiedza z zakresu potrzebnego na start
                </a>
              </p>
            }
          />
          <Phase
            header={"Decyzja"}
            number={4}
            description={<p>Gratulacje! Podejmujesz wyzwanie!</p>}
          />
        </div>
        {/*<nav>*/}
        {/*  <a*/}
        {/*    target={"_blank"}*/}
        {/*    href="https://hargreaveslansdown.wd3.myworkdayjobs.com/en-US/HLTech/job/Warsaw/FinTech-Challenge_R283/apply?source=Landing_Page"*/}
        {/*  >*/}
        {/*    <Button>*/}
        {/*      ZAREJESTRUJ SIĘ <IconArrow />*/}
        {/*    </Button>*/}
        {/*  </a>*/}
        {/*</nav>*/}
      </Container>
    </div>
  </Section>
);

export const Phase = (props: {
  number: number;
  header: string | React.ReactElement;
  description: React.ReactElement;
}) => (
  <div className={styles.phase}>
    <div>
      <h3>Etap</h3>
      <span>{props.number}</span>
    </div>
    <p style={{ fontWeight: "bold" }}>{props.header}</p>
    {props.description}
  </div>
);
