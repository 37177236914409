import * as React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import { Section } from "../Section/Section";
import { Container } from "../Layout/Layout";

import styles from "./SectionAbout.module.css";

interface SectionAbout {
  image: React.ReactElement;
}

export const SectionAbout = ({ image }: SectionAbout) => (
  <>
    <Section id={"about"} backgroundColor={"#fff"}>
      <Container>
        <div className={styles.grid}>
          <div>{image}</div>
          <div>
            <p>
              <span style={{ fontWeight: 600 }}>FinTech Challenge</span> to coś
              więcej niż hackathon! Jeśli masz za sobą pierwsze kroki w
              programowaniu, testowaniu lub analizie IT i chcesz rozwijać się w
              obszarze fintech – to jesteś w dobrym miejscu <br /> w odpowiednim
              czasie. Zdobędziesz praktyczne doświadczenie budując wraz ze swoim
              zespołem, pod okiem naszych mentorów <br /> - 2 aplikacje
              finansowe, a także będziesz walczyć o nagrody pieniężne!
            </p>
            <p>
              A co najważniejsze weźmiesz udział w szkoleniach <br /> z
              technologii zakresu: <br /> <br />
              <span
                style={{
                  fontWeight: 600,
                  color: "rgb(0, 0, 69)",
                }}
              >
                Backend, Frontend oraz QA: Java 11 , Spring Boot 2, Hibernate,
                Maven/Gradle, Docker, Create React App, TypeScript, Jest,
                Testing-Library, Spock, Groovy.
                <br />
                <br />
                Ponadto, w ramach challengu, udostępniamy w pełni skonfigurowane
                środowiska z K8s, Jenkinsem, pipeline'nami, gitem, issue
                trackerem i docker registry.
              </span>
            </p>
            <p style={{ fontWeight: 600 }}>Brzmi ciekawie?</p>
            <p>
              Zbierz zespół minimum 4, a maksymalnie 5 osób mających już
              pierwsze doświadczenia w obszarze: Frontend, Backend, QA oraz
              Analiza IT. Zespół musi posiadać kompetencje we wszystkich
              czterech obszarach, przy czym dopuszczamy sytuację, że jedna osoba
              odpowiada za dwa różne obszary oraz sytuację, w której dwie osoby
              odpowiadają za ten sam obszar.
            </p>
            <p>
              Nie masz zespołu, a chcesz wziąć udział w FinTech Challenge?{" "}
              <br /> – zaaplikuj indywidualnie, wskazując twoją specjalizację.
              <br />
              <br />
              Jeśli pozytywnie przejdziesz przez proces rekrutacji, przypiszemy
              Cię do utworzonego przez nas zespołu w wybranej przez Ciebie roli.{" "}
              <br /> <br />
              <AnchorLink href={"#recruitment-process"}>
                *szczegóły tutaj
              </AnchorLink>
            </p>
          </div>
        </div>
      </Container>
      <div className={styles.triangle} />
    </Section>
  </>
);
