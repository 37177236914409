import * as React from "react";
import classNames from "classnames/bind";

import styles from "./Section.module.css";
const cx = classNames.bind(styles);

interface SectionProps {
  id?: string;
  fullHeight?: boolean;
  backgroundColor?: string;
}

export const Section = ({
  id,
  fullHeight,
  backgroundColor,
  children,
}: React.PropsWithChildren<SectionProps>) => (
  <section
    id={id}
    style={{ backgroundColor }}
    className={cx({
      section: true,
      fullHeight,
    })}
  >
    {children}
  </section>
);
