import * as React from "react";

import { Section } from "../Section/Section";
import { Button } from "../Button/Button";

import styles from "./SectionVideo.module.css";

import IconArrow from "../../images/icons/arrow.svg";

interface SectionVideo {
  video: string;
}

export const SectionVideo = (props: SectionVideo) => (
  <Section id={"video"} backgroundColor={"#071d49"} fullHeight>
    <div className={styles.wrapper}>
      <video className={styles.video} autoPlay loop muted playsInline>
        <source src={props.video} type="video/mp4" />
      </video>
      {/*<a*/}
      {/*  target={"_blank"}*/}
      {/*  href="https://hargreaveslansdown.wd3.myworkdayjobs.com/en-US/HLTech/job/Warsaw/FinTech-Challenge_R283/apply?source=Landing_Page"*/}
      {/*>*/}
      {/*  <Button>*/}
      {/*    ZAREJESTRUJ SIĘ <IconArrow />*/}
      {/*  </Button>*/}
      {/*</a>*/}
    </div>
  </Section>
);
