import * as React from "react";

import { Modal, ModalVariant } from "./Modal/Modal";
import { ModalType } from "../../providers/ModalProvider";
import { RoleSkillList } from "../SectionRoles/SectionRoles";
import { useModal } from "../../hooks/useModal";

export const ModalFE = () => {
  const { hideModal } = useModal();

  return (
    <Modal onClose={() => hideModal(ModalType.fe)} variant={ModalVariant.large}>
      <RoleSkillList>
        <li>
          Czy pracowałeś/aś kiedyś nad aplikacją Reactową łączącą się z
          Backendem?
        </li>
        <li>Czy wiesz, czym są React hooki? </li>
        <li>Czy tworzyłeś/aś aplikacje zawierające formularze?</li>
        <li>
          Czy korzystałeś/aś kiedyś z któregoś z wymienionych narzędzi:
          TypeScript, Redux, @testing-library lub Formik?
        </li>
        <li>
          Czy posługiwałeś/aś się takimi metodami jak ".map()", ".filter()" czy
          ".reduce()"?
        </li>
        <li>
          Czy wiesz, co oznaczają elementy: header, nav, section, article,
          aside, footer w HMTLu?
        </li>
        <li>
          Czy wiesz, jak budować layout za pomocą Grid Layout CSS oraz Flex Box
          Layout CSS?
        </li>
        <li>
          Czy wiesz, na czym polega REST i jaka jest różnica miedzy metodami
          GET, POST, PATCH, PUT i DELETE?
        </li>
      </RoleSkillList>
    </Modal>
  );
};
