import * as React from "react";

import SEO from "../components/seo";
import { Challenge1 } from "../components/images/challenge1";
import { Challenge2 } from "../components/images/challenge2";
import { Layout } from "../components/Layout/Layout";

import { SectionVideo } from "../components/SectionVideo/SectionVideo";
import { SectionAbout } from "../components/SectionAbout/SectionAbout";
import { SectionBenefits } from "../components/SectionBenefits/SectionBenefits";
import { SectionMentors } from "../components/SectionMentors/SectionMentors";
import { SectionRoles } from "../components/SectionRoles/SectionRoles";
import { SectionRecruitment } from "../components/SectionRecruitment/SectionRecruitment";
import { SectionTimeline } from "../components/SectionTimeline/SectionTimeline";
import { SectionContact } from "../components/SectionContact/SectionContact";

import { IconBox } from "../components/IconBox/IconBox";
import { UserBio } from "../components/UserBio/UserBio";

import IconGear from "../images/icons/gear.svg";
import IconCooperation from "../images/icons/coop.svg";
import IconGadgets from "../images/icons/gadgets.svg";
import IconMentors from "../images/icons/mentorzy.svg";
import IconPrizeSingle from "../images/icons/nagroda-in.svg";
import IconPrizeTeam from "../images/icons/nagroda-team.svg";
import IconNetworking from "../images/icons/networking.svg";
import IconWisdom from "../images/icons/wisdom.svg";

import IntroVideo from "../images/intro-closed.mp4";
import FintechChallengeLogo from "../images/fintech-logo.png";

import Ciok from "../images/ciok.jpg";
import Filip from "../images/filip2.jpg";
import Kaczan from "../images/kaczan2.jpg";
import Karol from "../images/karol.jpg";
import Lidka from "../images/lidka.jpg";
import Magda from "../images/magda.jpg";
import Nowak from "../images/nowak.jpg";
import Parapura from "../images/parapura2.jpg";
import Marek from "../images/marek.jpg";

const IndexPage = () => (
  <>
    <SEO
      title={"HL Tech - fintech challenge"}
      lang={"pl"}
      image={
        "https://trello-attachments.s3.amazonaws.com/5f369fe8a768774bd36905e8/842x519/5db354a045c1398865194a5b51b41289/hltechfintechchallenge_06_rgb_white.png"
      }
      description={"Register until 26.08"}
    />
    <Layout>
      <SectionVideo video={IntroVideo} />
      <SectionAbout
        image={
          <img
            src={FintechChallengeLogo}
            width={400}
            alt="HL Tech fin-tech challenge"
          />
        }
      />
      <SectionBenefits>
        <IconBox
          Icon={<IconWisdom />}
          description={"Duża dawka wiedzy – technicznej i biznesowej"}
        />
        <IconBox
          Icon={<IconPrizeTeam />}
          description={
            <span>
              Nagrody dla zespołu - <br /> I miejsce: 15 000 PLN <br /> II
              miejsce: 10 000 PLN <br /> III miejsce: 5000 PLN
            </span>
          }
        />
        <IconBox
          Icon={<IconPrizeSingle />}
          description={
            "Nagroda indywidualna – skrócony proces rekrutacji jeśli zarówno Ty, jak i my będziemy zainteresowani dalszą współpracą :)"
          }
        />
        <IconBox
          Icon={<IconMentors />}
          description={
            <span>
              Wsparcie mentorów – <br /> nasi specjaliści przekażą Ci najlepsze
              praktyki i pomogą w pracy nad projektem.
            </span>
          }
        />
        <IconBox
          Icon={<IconGear />}
          description={
            <span>
              Doświadczenie – <br /> zbudujesz 2 aplikacje finansowe w
              najnowszych technologiach
            </span>
          }
        />
        <IconBox
          Icon={<IconCooperation />}
          description={
            <span>
              Współpraca – <br /> nauczysz się pracować w samowystarczalnym
              zespole
            </span>
          }
        />
        <IconBox
          Icon={<IconNetworking />}
          description={
            <span>
              Networking – <br /> nawiążesz kontakty bezcenne w rozwoju Twojej
              kariery
            </span>
          }
        />
        <IconBox Icon={<IconGadgets />} description={"Fajne gadżety"} />
      </SectionBenefits>
      <SectionMentors>
        <UserBio
          name={"Magdalena"}
          surname={"Korgól"}
          title={"Product Owner i Analityk IT"}
          avatarURL={Magda}
          description={
            "Od pięciu lat związana z technologiami w świecie finansów i płatności. Wolny czas najchętniej spędza przygotowując słodkości lub czytając literaturę faktu."
          }
        />
        <UserBio
          name={"Karol"}
          surname={"Maciołek"}
          title={"Frontend"}
          avatarURL={Karol}
          description={
            "Z HL Tech związany od początku kariery software developera. Kiedy nie pisze kodu, znajduje rozrywkę w grach komputerowych."
          }
        />
        <UserBio
          name={"Mariusz"}
          surname={"Ciok"}
          title={"Backend"}
          avatarURL={Ciok}
          description={
            "Z programowaniem związany od 10 lat. Prywatnie – fan żeglarstwa i gór."
          }
        />
        <UserBio
          name={"Grzegorz"}
          surname={"Parapura"}
          title={"Backend"}
          avatarURL={Parapura}
          description={
            "Przygodę z programowaniem rozpoczął od praktyk w HL Tech. Po godzinach wybiera sport na zmianę z pogłębianiem wiedzy z zakresu machine learning."
          }
        />
        <UserBio
          name={"Paweł"}
          surname={"Kaczan"}
          title={"Backend"}
          avatarURL={Kaczan}
          description={
            "Ponad 10 lat doświadczenia w wytwarzaniu oprogramowania z wykorzystaniem różnych technologii."
          }
        />
        <UserBio
          name={"Filip"}
          surname={"Szcześniak"}
          title={"Frontend"}
          avatarURL={Filip}
          description={
            "Zafascynowany programowaniem już od gimnazjum. W przerwach od kodowania żyje tenisem, górami i historią XX wieku."
          }
        />
        <UserBio
          name={"Łukasz"}
          surname={"Nowak"}
          title={"Frontend"}
          avatarURL={Nowak}
          description={
            "W czasie 10 lat w profesjonalnym programowaniu przeszedł ścieżkę od fullstacka do frontend developera. Jest fanem lotnictwa, biegania i dobrej muzyki."
          }
        />
        <UserBio
          name={"Lidia"}
          surname={"Korona"}
          title={"QA"}
          avatarURL={Lidka}
          description={
            "9 lat doświadczenia w IT, w tym 2 lata w HL Tech. Lubi podróżować i spędzać Sylwestra w ciekawych miejscach, np. w Emiratach lub na Islandii."
          }
        />
        <UserBio
          name={"Marek"}
          surname={"Wójtowicz"}
          title={"QA"}
          avatarURL={Marek}
          description={
            "Zachowaniem jakości w IT zajmuje się od 2,5 roku. W wolnym czasie trenuje różne gry bilardowe."
          }
        />
      </SectionMentors>
      <SectionRoles />
      <SectionRecruitment />
      <SectionTimeline>
        <Challenge1 />
        <Challenge2 />
      </SectionTimeline>
      <SectionContact />
    </Layout>
  </>
);

export default IndexPage;
