import * as React from "react";

import { Modal, ModalVariant } from "./Modal/Modal";
import { ModalType } from "../../providers/ModalProvider";
import { RoleSkillList } from "../SectionRoles/SectionRoles";
import { useModal } from "../../hooks/useModal";

export const ModalBE = () => {
  const { hideModal } = useModal();

  return (
    <Modal onClose={() => hideModal(ModalType.be)} variant={ModalVariant.large}>
      <RoleSkillList>
        <li>
          Czy potrafisz zaimplementować prosty algorytm w Javie, który na
          przykład obliczy wartość n! dla zadanego n jako parametr aplikacji?
        </li>
        <li>Czy wykorzystywałeś/aś wzorce projektowe?</li>
        <li>
          Czy wiesz, jak w praktyce wykorzystać podstawowe mechanizmy Spring
          Framework, takie jak Dependency Injection, Container, Bean, Autowired?
        </li>
        <li>
          Czy jesteś w stanie zaimplementować model klient - serwer z
          wykorzystaniem protokołu HTTP (na przykład z użyciem modułu Spring
          Web)?
        </li>
        <li>
          Czy integrowałeś/aś aplikację z bazą danych (JDBC, JPA, Hibernate,
          Spring Data)?
        </li>
        <li>
          Czy potrafisz definiować model bazy danych oraz wykorzystywać język
          zapytań?
        </li>
      </RoleSkillList>
    </Modal>
  );
};
