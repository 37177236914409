import * as React from "react";

interface IconBoxProps {
  Icon?: React.ReactElement;
  description: string | React.ReactElement;
}

import styles from "./IconBox.module.css";

export const IconBox = ({ Icon, description }: IconBoxProps) => (
  <div className={styles.box}>
    {Icon}
    <p>{description}</p>
  </div>
);
