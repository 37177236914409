import * as React from "react";
import { PropsWithChildren } from "react";

import styles from "./Button.module.css";

interface ButtonProps {
  onClick?(): void;
}

export const Button = (props: PropsWithChildren<ButtonProps>) => (
  <button
    aria-disabled={"false"}
    aria-roledescription={"Call to action button"}
    onClick={props.onClick}
    className={styles.button}
  >
    {props.children}
  </button>
);
