import * as React from "react";
import classNames from "classnames/bind";
import ReactDOM from "react-dom";

import { Button } from "../../Button/Button";

import { ModalContext } from "../../../providers/ModalProvider";
import { useOutsideClick } from "../../../hooks/useOutsideClick";

import IconArrow from "../../../images/icons/arrow.svg";

import styles from "./Modal.module.css";

const cx = classNames.bind(styles);

export enum ModalVariant {
  small = "small",
  large = "large",
  mobile = "mobile",
  xxl = "xxl",
}

interface ModalProps {
  variant: ModalVariant;
  onClose?(): void;
}

export const Modal = ({
  children,
  variant,
  onClose,
}: React.PropsWithChildren<ModalProps>) => {
  const { modalRef } = React.useContext(ModalContext);

  const contentRef = React.useRef<HTMLDivElement | null>(null);

  useOutsideClick(contentRef, onClose);

  return modalRef
    ? ReactDOM.createPortal(
        <Overlay>
          <div
            ref={contentRef}
            className={cx({
              content: true,
              [variant]: true,
            })}
            onClick={onClose}
            data-testid="modal"
          >
            {children}
            <nav className={styles.navigation}>
              <Button onClick={onClose}>
                Okej! <IconArrow />
              </Button>
            </nav>
          </div>
        </Overlay>,
        modalRef
      )
    : null;
};

const Overlay = (props: React.PropsWithChildren<{}>) => (
  <div className={styles.overlay}>{props.children}</div>
);
