import * as React from "react";
import { PropsWithChildren } from "react";

import { Section } from "../Section/Section";
import { Container } from "../Layout/Layout";
import { SectionHeader } from "../SectionHeader/SectionHeader";

import styles from "./SectionBenefits.module.css";

interface SectionBenefits {}

export const SectionBenefits = ({
  children,
}: PropsWithChildren<SectionBenefits>) => (
  <Section id={"benefits"} backgroundColor={"#000045"}>
    <div className={styles.background}>
      <Container>
        <SectionHeader header={"Dlaczego"} highlighted={"warto?"} />
        <div className={styles.benefits}>{children}</div>
      </Container>
    </div>
    <div className={styles.triangle} />
  </Section>
);
