import * as React from "react";

import { Section } from "../Section/Section";
import { Button } from "../Button/Button";
import { Container } from "../Layout/Layout";
import { FintechLogo } from "../images/fintech-logo";

import IconArrow from "../../images/icons/arrow.svg";

import styles from "./SectionContact.module.css";

export const SectionContact = () => (
  <Section id={"contact"} backgroundColor={"#fff"}>
    <Container>
      <div className={styles.grid}>
        <div>
          <FintechLogo />
        </div>
        <div className={styles.contact}>
          <p>Jeśli masz pytania, skontaktuj się z nami:</p>
          <a href="mailto:fintechallenge@hltech.com">
            <Button>
              fintechallenge@hltech.com <IconArrow />
            </Button>
          </a>
        </div>
      </div>
    </Container>
    <div className={styles.triangle} />
  </Section>
);
