import * as React from "react";
import { PropsWithChildren } from "react";

import { Section } from "../Section/Section";
import { SectionHeader } from "../SectionHeader/SectionHeader";
import { Container } from "../Layout/Layout";

import styles from "./SectionMentors.module.css";

export const SectionMentors = (props: PropsWithChildren<{}>) => (
  <Section id={"mentors"} backgroundColor={"#0A70DC"}>
    <Container>
      <SectionHeader header={"NASI"} highlighted={"MENTORZY"} />
      <div className={styles.grid}>{props.children}</div>
    </Container>
    <div className={styles.triangle} />
  </Section>
);
