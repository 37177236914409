import * as React from "react";

import { Section } from "../Section/Section";
import { Container } from "../Layout/Layout";
import { SectionHeader } from "../SectionHeader/SectionHeader";

import { ModalType } from "../../providers/ModalProvider";
import { ModalQA } from "../modals/ModalQA";
import { ModalFE } from "../modals/ModalFE";
import { ModalBE } from "../modals/ModalBE";
import { ModalAnalyst } from "../modals/ModalAnalyst";
import { useModal } from "../../hooks/useModal";

import styles from "./SectionRoles.module.css";

export const SectionRoles = () => {
  const { isModalOpen, showModal } = useModal();

  return (
    <Section id={"roles"} backgroundColor={"#000045"}>
      <Container>
        <div className={styles.grid}>
          <div>
            <SectionHeader
              header={"DLA"}
              highlighted={"KOGO"}
              description={"FINTECH Challlenge jest dla Ciebie jeśli:"}
            />
            <ul className={styles.list}>
              <li>chcesz w praktyczny sposób zdobyć wiedzę w krótkim czasie</li>
              <li>
                masz już pierwsze doświadczenia w roli programisty, QA lub
                analityka IT….
              </li>
              <li>
                … i możesz się tym pochwalić udostępniając nam swoje konto na
                GitHubie <br /> (to nie jest obowiązkowy wymóg, ale zdecydowanie
                nice to have :)
              </li>
              <li>interesujesz się najnowszymi technologiami</li>
              <li>komunikatywnie posługujesz się językiem angielskim</li>
              <li>
                masz znajomych, z którymi stworzysz samowystarczalny zespół w
                obszarze: Frontend/Backend/QA oraz Analiza IT
              </li>
              <li>
                chcesz zaaplikować indywidualnie i dostać się do zespołu
                stworzonego przez nas
              </li>
            </ul>
          </div>
          <div>
            <p>
              Aplikuj, jeśli czujesz się pewnie przynajmniej w jednym z
              poniższych obszarów. Kliknij i zobacz jaki zakres wiedzy jest
              potrzebny na start!
            </p>
            <div className={styles.roles}>
              <Role onClick={() => showModal(ModalType.fe)} name={"Frontend"} />
              <Role onClick={() => showModal(ModalType.be)} name={"Backend"} />
              <Role
                onClick={() => showModal(ModalType.qa)}
                name={"Quality assurance"}
              />
              <Role
                onClick={() => showModal(ModalType.analyst)}
                name={"Analiza IT"}
              />
            </div>
          </div>
        </div>
      </Container>
      {isModalOpen(ModalType.be) && <ModalBE />}
      {isModalOpen(ModalType.fe) && <ModalFE />}
      {isModalOpen(ModalType.analyst) && <ModalAnalyst />}
      {isModalOpen(ModalType.qa) && <ModalQA />}
    </Section>
  );
};

const Role = (props: { name: string; onClick: () => void }) => (
  <div onClick={props.onClick} className={styles.role}>
    <p>{props.name}</p>
  </div>
);

export const RoleSkillList = (props: React.PropsWithChildren<{}>) => (
  <ul className={styles.roleList}>{props.children}</ul>
);
