import * as React from "react";

import { Modal, ModalVariant } from "./Modal/Modal";
import { ModalType } from "../../providers/ModalProvider";
import { RoleSkillList } from "../SectionRoles/SectionRoles";
import { useModal } from "../../hooks/useModal";

export const ModalAnalyst = () => {
  const { hideModal } = useModal();

  return (
    <Modal
      onClose={() => hideModal(ModalType.analyst)}
      variant={ModalVariant.large}
    >
      <RoleSkillList>
        <li>Czy jesteś komunikatywny/a?</li>
        <li>Czy potrafisz zadawać pytania? </li>
        <li>Czy rozumiesz cel tworzenia wizji produktu?</li>
        <li>
          Czy wiesz, czym różnią się wymagania funkcjonalne od
          niefunkcjonalnych?
        </li>
        <li>
          Czy znasz przynajmniej kilka metod pozyskiwania i definiowania wymagań
          biznesowych?
        </li>
      </RoleSkillList>
    </Modal>
  );
};
