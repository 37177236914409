import * as React from "react";

import styles from "./SectionHeader.module.css";

interface SectionHeaderProps {
  header: string;
  highlighted?: string;
  description?: string;
}

export const SectionHeader = (props: SectionHeaderProps) => (
  <div className={styles.desc}>
    <h2>
      {props.header} <span>{props.highlighted}</span>
    </h2>
    {props.description && <p>{props.description}</p>}
  </div>
);
